body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* ====== ANIMATIONS ====== */

/* === OPEN MENUS === */

@keyframes closeRightMenu {
  from {right: 0px;}
  to {right: -400px;}
}

@keyframes openRightMenu {
  from {right: -400px;}
  to {right: 0px;}
}

@keyframes openLeftMenu {
  from {left: -400px;}
  to {left: 0px;}
}

@keyframes closeLeftMenu {
  from {left: 0px;}
  to {left: -400px;}
}

@keyframes introArrow {
  from {opacity: 0%;}
  to {opacity: 100%}
}

.open-right-menu {
  animation: openRightMenu 1s forwards;
}

.close-right-menu {
  animation: closeRightMenu 1s forwards;
}

.open-left-menu {
  animation: openLeftMenu 1s forwards;
}

.close-left-menu {
  animation: closeLeftMenu 1s forwards;
}

.left-menu-triangle-close-animation {
  animation: triangleAppear 1s forwards;
}

.left-menu-triangle-open-animation {
  animation: triangleDisappear 1s forwards;
}

.right-menu-triangle-close-animation {
  animation: triangleAppear 1s forwards;
}

.right-menu-triangle-open-animation {
  animation: triangleDisappear 1s forwards;
}

.intro-arrow {
  animation: introArrow 2s forwards;
  animation-delay: 3s;
  color: var(--mint-green);
  opacity: 0%;
}
/* === CLOSED MENUS === */

@keyframes closeIndexMenuClosed {
  from {right: 0px;}
  to {right: -210px;}
}

@keyframes openIndexMenuClosed {
  from {right: -210px;}
  to {right: 0px;}
}

@keyframes openThemesMenuClosed {
  from {left: -210px;}
  to {left: 0px;}
}

@keyframes closeThemesMenuClosed {
  from {left: 0px;}
  to {left: -210px;}
}

.open-themes-menu-closed {
  animation: openThemesMenuClosed 1s forwards;

}

.close-themes-menu-closed {
  animation: closeThemesMenuClosed 1s forwards;
}

.open-index-menu-closed {
  animation: openIndexMenuClosed 1s forwards;

}

.close-index-menu-closed {
  animation: closeIndexMenuClosed 1s forwards;
}


/* ====== FONTS ====== */

h1 {
  font-family: myriad-pro-semi-condensed,sans-serif;
  font-weight: 700;
  font-style: normal;
}

h2 {
  font-family: myriad-pro-semiextended,sans-serif;
  font-weight: 900;
  font-style: normal;
}

h3 {
  font-family: myriad-pro-semiextended,sans-serif;
  font-weight: 900;
  font-style: normal;
}

h4 {
  font-family: myriad-pro-semiextended,sans-serif;
  font-weight: 900;
  font-style: normal;
  font-size: .9em !important;
}

/* You may need this non-extended font later in development */

/* h1 {
  font-family: myriad-pro,sans-serif;
  font-weight: 900;
  font-style: normal;
} */

p {
  font-family: neuzeit-grotesk, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
}

a {
  color: var(--cobalt);
}

ul { 
  padding: 0 0 0 2vW;
  margin: 0;
}

ol {
  padding: 0 0 0 2vW;
}


li {
  font-family: myriad-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
  list-style: none;
  margin-left: 20px;
}

.bullets {
  list-style: circle;
  padding-top: 5px;
}

.dashes {
  list-style: square;
  padding-top: 5px;
}

.decimal {
  list-style: decimal;
  padding-top: 5px;
}

.numbers {
  list-style: decimal;
  padding-top: 10px;
}

.coporate {
  font-family: corporate-s, sans-serif;
  font-weight: 400;
  font-style: normal;
}

.cora {
  font-family: cora, sans-serif;
  font-weight: 400;
  font-style: normal;
}

.neuzit {
  font-family: neuzeit-grotesk, sans-serif;
  font-weight: 400;
  font-style: normal;
}

.subtitle {
  text-transform: uppercase;
  margin: 0;
}

.no-underline {
  text-decoration: none;
}

/* ====== HEIGHT & WIDTH ====== */

.h-fifty {
  height: 50%;
}

.h-sixty {
  height: 60%;
}

.h-eighty {
  height: 80%;
}

.h-forty {
  height: 40%;
}

.h-thirty-three {
  height: 33%;
}

.h-one-hundred {
  height: 100%;
}

.one-hundred-vh {
  height: calc(100vH - 16px);
}

.min-height-one-hundred-vh {
  min-height: 100vH;
}

.ninety-vh {
  height: 90vH;
}

.h-sixty {
  height: 60%;
}

.h-200px {
  height: 200px;
  margin-top: 15px;
}

.h-150px {
  height: 150px;
  margin-top: 15px;
}

.h-100px {
  height: 100px;
  margin-top: 15px
}

.w-one-hundred {
  width: 100%;
}

.w-seventy {
  width: 70%;
}

.w-eighty {
  width: 80%;
}

.w-ninety {
  width: 90%;
}

.w-seventy-five {
  width: 75%;
}

/* the exemptions are for the moments when we do not need the calc to account for the menus */

.w-sixty {
  width: 60%;
}

.w-sixty-exempt {
  width: 60%;
}

.w-fifty {
  width: 50%;
}

.w-fifty-exempt {
  width: 50%;
}

 {
  width: 47%;
}

.w-forty {
  width: 40%;
}

.w-forty-exempt {
  width: 40%;
}

.w-thirty {
  width: 30%;
}

.w-twenty {
  width: 20%;
}

.w-twenty-five {
  width: 25%;
}

.w-twenty-five-exempt {
  width: 25%;
}

/* ====== SPACING ====== */

.al-center {
  align-items: center;
}

.baseline {
  align-items: baseline;
}

.jc-baseline {
  align-items: flex-end;
}

.flex-reverse {
  flex-direction: row-reverse;
}

.column {
  flex-direction: column;
}

.column-reverse {
  flex-direction: column-reverse;
}

.flex {
  display: flex;
}

.al-flex-end {
  align-items: flex-end;
}

.flex-end {
  justify-content: flex-end;
}

.flex-wrap {
  flex-wrap: wrap;
}

.jc-center {
  justify-content: center;
}

.space-btwn {
  justify-content: space-between;
}

.space-around {
  justify-content: space-around;
}

/* ====== POSITION ====== */

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.left {
  left: 0%;
}

.fifty-fifty {
  top: 65%;
  left: 25%;
}

.overflow-hidden {
  overflow: hidden;
}

.relative {
  position: relative;
}

.z-index {
  z-index: 999 !important;
}

.z-index-10 {
  z-index: 10;
}

.z-index-11 {
  z-index: 11;
}

/* ====== MARGIN ====== */

.bottom-100px {
  margin-bottom: 100px;
}

.top-100px {
  margin-top: 100px;
}

.left-margin-10px {
  margin-left: 10px;
}

.left-margin-20px-desktop-only {
  margin-left: 20px;
}

.right-margin-10px {
  margin-right: 10px;
}

.left-10px {
  left: 10px;
}

.right-10px {
  right: 10px;
}

.left-negative-100px{
  left: -100px;
}

.top-margin-10px {
  margin-top: 10px;
}

.top-10px {
  top: 10px;
}

.bottom-10px {
  bottom: 10px;
}

.top-30px {
  top: 20px;
}

/* ====== PADDING ====== */

.no-top-padding {
  padding-top: 0;
  margin-top: 0;
}

.no-bottom-padding {
  padding-bottom: 0;
  margin-bottom: 0;
}

.bottom-padding {
  padding-bottom: 20px;
}


.top-padding {
  padding: 15vH 0 5vH 0;
}

.only-top-padding {
  padding-top: 1vH;
}

.only-top-margin {
  margin-top: 1vH;
}

.no-padding {
  padding: 0;
  margin: 0;
}

.side-padding {
  padding: 0vH 1vW;
}

.stnd-padding {
  padding: 1vH 1vW;
}

.double-padding {
  padding: 2vH 2vW;
}

/* ====== COLORS ====== */

:root {
  --cobalt: #0b0b84;
  --sand: #e4dcd1;
  --evergreen: #317C41;
  --mint-green: #d7f0dd;
  --preserved-lemon: #CCCC21;
  --pumpkin: #E26E40;
  --app-height: 100%;
}

.black {
  background-color: black;
}

.black-txt {
  color: black;
}
.cobalt {
  background-color: var(--cobalt);
}

.sand-gradient {
  background-image: linear-gradient(105deg, var(--sand) 0%, var(--sand) 50%, transparent 50%);
}

.mint-gradient {
  background-image: linear-gradient(105deg, var(--mint-green) 0%, var(--mint-green) 50%, transparent 50%);
}

.cobalt-txt {
  color: var(--cobalt);
}

.evergreen {
  background-color: var(--evergreen);
}

.evergreen-txt {
  color: var(--evergreen);
}

.mint-green {
  background-color: var(--mint-green);
}

.mint-green-txt {
  color: var(--sand);
}

.preserved-lemon {
  background-color: var(--preserved-lemon);
}

.preserved-lemon-txt {
  color: var(--preserved-lemon);
}

.pumpkin {
  background-color: var(--pumpkin);
}

.pumpkin-txt {
  color: var(--pumpkin);
}

.sand {
  background-color: var(--sand);
}

.sand-txt {
  color: var(--sand);
}

.sand-gradient-50 {
  background-image: linear-gradient(0deg, var(--sand) 0%, var(--sand) 50%, transparent 50%);
}

.black-gradient-50 {
  background-image: linear-gradient(0deg, var(--preserved-lemon) 0%, var(--preserved-lemon) 50%, transparent 50%);
}

.cobalt-gradient-50 {
  background-image: linear-gradient(0deg, var(--cobalt) 0%, var(--cobalt) 50%, transparent 50%);
}

.sand-gradient-50-down {
  background-image: linear-gradient(180deg, var(--sand) 0%, var(--sand) 50%, transparent 50%);
}

.txt-al-center {
  text-align: center;
}

.txt-al-right {
  text-align: right;
}

/* ====== BUTTONS ====== */

.small-bttn {
  align-items: center;
  border-radius: 10px;
  display: flex;
  font-family: neuzeit-grotesk, sans-serif;
  font-weight: 900;
  font-style: normal;
  height: 30px;
  justify-content: center;
  padding: 0vH 2vW;
  text-decoration: none;
  text-transform: uppercase;
  transition-duration: .5s;
  width: 120px;
}

.small-bttn:hover {
  background-color: var(--mint-green);
  color: black;
  cursor: pointer;
  transition-duration: .5s;
}

.button-div-height {
  height: 60px;
}

/* ====== BORDER ====== */

.border-radius {
  border-radius: 10px;
}

.sand-border {
  border: 2px solid var(--sand)
}

.sand-border-right {
  border-right: 5px solid var(--sand);
}

.sand-border-left {
  border-left: 5px solid var(--sand);
}

.sand-border-left-thin {
  border-bottom: 1px solid var(--sand);
}

.sand-border-bottom {
  border-bottom: 5px solid var(--sand);
}

.black-border-bottom {
  border-bottom: 5px solid black;
}

.black-border-bottom-thin {
  border-bottom: 2px solid black;
}


.black-border-u-shape {
  border-left: 2px solid black;
  border-right: 2px solid black;
  border-bottom: 2px solid black;
}

.black-border {
  border: 2px solid black;
}

/* ====== SCROLL BAR ====== */


/* ====== MENUS ====== */

.cursor {
  cursor: pointer;
}

.condensed-menu-width {
  width: 10vW;
}

.menu-bttn {
  margin-bottom: 5px;
  padding: 0vH 3vW;
  text-align: center;
  text-transform: uppercase;
}

.triangle {
  width: 40px;
  position: absolute;
  top: 47%;
  cursor: pointer;
}

.triangle-size {
  width: 40px;
  cursor: pointer;
  position: absolute;
  top: 47%;
}

.menu-left-border {
  border-left: 5px solid black;
}

.menu-right-border {
  border-right: 5px solid black;
}

.vault-padding {
  padding: 0vH 1vW;
}


/* === INDEX MENU === */

.width-40px {
  width: 40px;
}

.index-condensed-menu {
  position: fixed;
  right: 0%;
}

.index-list li {
  padding-bottom: 2px;
}

.index-list li:hover {
  border-bottom: 1px solid black;
  padding-bottom: 1px;
}

.index-menu {
  min-width: 300px;
  position: fixed;
  right: -300px;
  width: 25vW;
  overflow: hidden;
}

.index-entries {
  border-bottom: 5px solid black;
  border-top: 5px solid black;
  height: 60vH;
  overflow: scroll;
  margin: 0vH 1vW 0vH 50px;
}

.index-entries a {
  text-decoration: none;
  color: black;
  cursor: pointer;
}

.index-location {
  border-top: 5px solid black;
  margin: 0;
  text-transform: uppercase;
  font-size: 1.25em;
}

.index-location-first {
  margin: 0;
  text-transform: uppercase;
  font-size: 1.25em;

}

.index-sub-title {
  font-family: myriad-pro-semiextended,sans-serif;
  font-weight: 900;
  font-style: normal;
  margin: 0;
  padding-left: 1vW;
  text-transform: uppercase;
  font-size: 1em;
}

#triangle-index-close {
  left: 0%;
  transform: rotate(180deg);
}

#triangle-index-open {
  left: 0%;
}

/* === THEMES MENU === */

.theme-condensed-menu {
  position: fixed;
  left: 0%;
}

.max-width-150px {
  max-width: 150px;
}

.themes-menu {
  position: fixed;
  left: -250px;
  width: 25vW;
}

#triangle-theme-close {
  right: 0%;
}

#triangle-theme-open {
  transform: rotate(180deg);
}

/* ====== HOMEPAGE ====== */

.home-border {
  border-bottom: 5px solid var(--preserved-lemon);
}

.home-height {
  height: 90vH;
  background-image: url('/public/Images/Pictures/Home.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  position: relative;
}

.home-section-padding {
  padding-top: 0vH;
  padding-bottom: 5vH;
}

.home-slash {
  height: 90vH;
  position: absolute;
  bottom: 0%;
  right: 200px;
}

.home-text {
  color: var(--mint-green);
  -webkit-text-stroke: black;
  -webkit-text-stroke-width: .5px;
  font-size: 18em;
  margin: 30px;
}

.home-text-width {
  padding-top: 10vH;
  width: 60vW;
}

.top-black-border {
  border-top: 2px solid black;
}

.home-photo {
  width: 80vW;
}

/* ====== BIORGRAPHY ====== */

.bio-slash {
  height: 300px;
}

/* ====== ESSAY ====== */

.essay-heading {
  margin-left: 20px;
}

.essay-author {
  margin-left: 10px;
  max-width: 250px;
  text-align: right;
}

.essay-title-height {
  height: 300px;
}

.essay-padding {
  padding-left: 20vW;
  padding-right: 20vW;
}

.essay-slash {
  position: absolute;
  left: 15%;
  height: 300px;
}

/* ====== TRANSCRIPTION ====== */

.transcription-details {
  font-size: 15px;
}

.transcript-slash {
  height: 300px;
  margin-right: 10px;
}

.transcription {
  margin: 0;
}

/* ====== VIDEO ====== */

.video {
  padding: 2vH;
  width: 74vW;
}

.video-title-width {
  width: 10vW;
}

.iframe {
  width: 53vW;
  height: 30vW;
}

.h-fifty-six {
  height: 56%;
}

.video-details-width {
  width: 50vW;
}

/* ====== RESOURCES MENTIONED ====== */

.resources-list li {
  font-weight: 900;
  border-bottom: 5px solid black;
  padding: 3px 0;
}

.resources-list {
  padding-left: 0;
}

.resources-subtitle {
  text-transform: uppercase;
  margin: 4vH 0 0 0;
}

.resources-title-padding {
  padding-left: 20vW;
}

.resources-width {
  width: 35vW;
}

/* ====== GATHERED MATERIALS ====== */

.container {
  overflow-x: scroll;
  width: 100vW;
  padding-bottom: none;
  height: var(--app-height)
}

.intro-panel-width {
  width: 630px;
}

.intro-title {
  font-size: 50px;
  margin: 0;
  padding: 0vH 2vW;
}

.intro-width {
  width: 230px;
}

.panel-1800 {
  width: 1800px;
}

.panel-1400 {
  width: 1400px;
}

.panel-1200 {
  width: 1200px;
}

.panel-700 {
  width: 700px;
}

.panel-900 {
  width: 900px;
}

/* === ARCHIVAL THINKING === */

.ar-container-length {
  width: 7000px;
}

.ar-panel-1 {
  background-image: url('/public/Images/Pictures/ArchivalThinking1.svg');
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.ar-slash {
  position: absolute;
  height: 80vH;
  left: 20%;
  top: 70px;
}

.bebe-seattle {
  background-image: url('/public/Images/Pictures/BebeSeattle.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
}

.ishmael{
  position: absolute;
  width: 75%;
  right: 0%;
  top: 10%;
  z-index: 0;
}

.practice {
  background-color: lightblue;
  border: 1px solid black;
  height: 100px;
  width: 20vW;
  overflow-x: scroll;
  }

.practice-txt {
  width: 40vW;
  margin: 0;
  }


/* === METHODS === */

.m-container-length {
  width: 9100px;
}

.methods-panel-1 {
  background-image: url('/public/Images/Pictures/Methods.png');
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.methods-margin {
  margin-top: 150px;
}

.m-slash {
  position: absolute;
  height: 80vH;
  left: 70%;
  top: 70px;
}

.slash {
  height: 80vH;
  left: 70%;
  top: 70px;
}

/* === CONTEXT === */

.c-container-length {
  width: 7500px;
}

.context-panel-1{
  background-image: url('/public/Images/Pictures/Context.svg');
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.context-margin{
  margin-top: 85px;
}

.c-slash {
  position: absolute;
  height: 80vH;
  left: 45%;
  top: 70px;
}

.large-slash {
  height: 95vH;
  left: 60%;
}

/* ====== WHY IT MATTERS ====== */

.w-container-length {
  width: 9000px;
}

.white-box {
  width: 300px;
  height: 300px;
  right: 0%;
  top: 0%;
}

.why-it-matters-img-2 {
  bottom: 0%;
  height: 450px;
  right: 0%;
}

.why-it-matters-margin {
  margin-top: 230px;
}

.why-it-matters-panel-1 {
  background-image: url('/public/Images/Pictures/WhyItMatters.png');
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.w-slash {
  position: absolute;
  height: 80vH;
  left: 30%;
  top: 70px;
}
