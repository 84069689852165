/* ============ MOBILE ============ */

@media screen and (max-width: 800px) {


    /* ====== BORDERS ====== */
    .no-mobile-border {
      border: none;
    }
    
    .top-mobile-sand-border {
      border-top: 5px solid var(--sand);
    
    }

    /* ====== SCROLLBAR ====== */

    .container::-webkit-scrollbar {
      width: 0px !important;
    }

    /* ====== DISPLAY ====== */
    
    .display-none {
      display: none;
    }
    
    /* ====== HEIGHT & WIDTH ====== */

    .one-hundred-vh {
      height: 100vH;
    }
    
    .h-200px {
      height: 250px;
    }
    
    .h-150px {
      height: 100%;
      min-height: 200px !important;
      padding-top: 20px !important;
      padding-bottom: 20px !important;
    }
    
    .h-100px {
      height: 100%;
      min-height: 150px !important;
      margin-top: 15px;
      padding-top: 20px !important;
      padding-bottom: 20px !important;
    }
    
    .h-fifty-mobile-100 {
      height: 100%;
    }

    .h-thirty-mobile-100 {
      height: 100%;
    }

    .h-thirty-three {
      height: 100%;
    }

    .h-sixty {
      height: 100%;
    }
    
    .mobile-h-250px {
      height: 250px;
    }
    
    .mobile-one-hundred-vh {
      height: 100vH;
    }
    
    .mobile-76vW {
      width: 76vW;
    }
    
    .mobile-width {
      width: 65vW;
    }
    
    .w-fifty {
      width: calc(100% - 2vW);
    }
    
    .w-fifty-exempt {
      width: 100%;
    }

    .mobile-w-forty-seven-border-adjust {
      width: calc(100% - 2vW + 4px) !important;
    }
    
    .w-forty-seven {
      width: calc(100% - 2vW);
    }
    
    .w-forty-seven-split {
      width: calc(100% - 2vW);
    }
    
    .w-forty {
      width: calc(100% - 2vW);
    }

    .w-forty-exempt {
      width: 100%;
    }
    
    .w-thirty {
      width: calc(100% - 2vW);
    }
    
    .w-seventy-five {
      width: calc(100% - 2vW);
    }
    
    .w-seventy {
      width: calc(100% - 2vW);
    }
    
    .w-eighty {
      width: calc(100% - 2vW);
    }
    
    .w-sixty {
      width: calc(100% - 2vW);
    }
    
    .w-sixty-exempt {
      width: 100%;
    }

    .w-twenty-five {
      width: calc(100% - 2vW);
    }
    
    .w-twenty-five-exempt {
      width: 100%;
    }

 
    /* ====== POSITION ====== */
    
    .mobile-relative {
      position: relative;
    }
    
    .left-negative-100px {
      left: 0px;
    }
    
    /* ====== SPACING ====== */
    
    .mobile-flex {
      display: flex;
    }
    
    .mobile-flex-end {
      justify-content: flex-end;
    }

    .mobile-al-end {
      align-items: flex-end;
    }

    .mobile-al-start {
      align-items: flex-start;
    }
    
    .mobile-al-center {
      align-items: center;
    }
    
    .mobile-jc-center{
      justify-content: center;
    }
    
    .mobile-column {
      flex-direction: column ;
    }
    
    .mobile-column-reverse {
      flex-direction: column-reverse ;
    }

    /* ====== PADDING ====== */
    
    .mobile-padding {
      padding-top: 4vH ;
    }
    
    .mobile-side-padding {
      padding-left: 12vW;
      padding-right: 12vW;
    }

    .side-padding {
      padding: 0vH 2vW;
    }

    .top-padding {
      padding-top: 5vH;
    }
    
    /* ====== MARGIN ====== */
    
    .mobile-top-100px {
      margin-top: 100px;
    }
    
    .mobile-top-20px {
      margin-top: 20px;
    }
    
    .mobile-top-10px {
      margin-top: 10px;
    }
    
    .mobile-bottom-10px {
      margin-bottom: 10px;
    }
    
    .left-margin-20px-desktop-only {
      margin-left: 0px;
    }
    
    /* ====== MENUS ====== */
    
    .triangle-size {
      width: 20px !important;
    }

    .triangle {
      width: 20px !important;
    }
    
    .width-40px {
      width: 20px !important;
    }

    
    /* ====== HOME ====== */
    
    .home-text {
      font-size: 6em;
    }
    
    .home-slash {
      height: 80vH;
      position: absolute;
      bottom: 0%;
      left: 25%;
    }
    
    .home-height {
      height: 80vH;
    }

    .intro-arrow-container {
      height: 22vH;
    }
    
    /* ====== GATHERED MATERIALS ====== */
    
 
    
    .ar-container-length {
      width: 100vW;
    }

    .container-length {
      width: 100vW;
    }
    
    .c-container-length {
      width: 100vW;
    }
    
    .m-container-length {
      width: 100vW;
    }

    .w-container-length {
      width: 100vW;
    }
    
    .why-it-matters-panel-1  {
      background-position: right;
      min-height: 500px;
      
    }
    
    .why-it-matters-img-2 {
      position: relative;
      display: block;
      width: 74vW;
      height: auto;
    }
    
    .intro-panel-width {
      width: 100vW;
    }
    
    .intro-title {
      font-size: 50px;
      margin: 0;
      padding: 0vH 2vW;
    }
    
    .methods-panel-1 {
      height: 100vH;
      min-height: 500px;
      background-position: left;
    }
    
    .intro-width {
      width: 65vW;
    }
    
    .panel-1400 {
      width: 76vW;
    }
    
    .panel-1200 {
      width: 76vW;
    }
    
    .panel-1800 {
      width: 76vW;
    }
    
    .panel-700 {
      width: 76vW;
    }
    
    .panel-900 {
      width: 76vW;
    }
    
    /* === ARCHIVAL THINKING === */
    
    .ishmael{
      position: relative;
      width: 100%;
      right: 0%;
      top: 10%;
      z-index: 0;
    }
    
    /* ====== TRANSCRIPTION ====== */
    
    .mobile-transcript-width{
      width: 56vW;
    }
    
    .transcript-slash {
      height: 250px;
    }
    /* ====== WRITING ====== */
    
    .essay-slash {
      height: 200px;
    }

    .bio-width {
      width: 65vW;
    }
    
    .bio-slash {
      height: 200px;
    }

    .essay-title-height {
      height: 200px;
    }
    
    .essay-author {
      text-align: left;
    }

    .essay-padding {
      padding-left: 18vW;
      padding-right: 18vW;
    }
    /* ====== VIDEO ====== */
    
    iframe {
      height: 42vW;
      width: 75vW;
    }

    .iframe {
      height: 42vW;
      width: 75vW;
    }
    
    .video {
      display: flex;
      justify-content: center;
      padding: 2vH 0vW;
      width: 100vW;
    }
    
    .video-title-width {
      width: 0vW;
    }

    .video-details-width {
      width: 65vW;
    }
    
    /* ====== HOMEPAGE ====== */
    
    .home-text-width {
      width: 65vW;
    }
    
    }